body {
  font-family: 'Roboto', sans-serif; /* Fontul implicit Material UI */
  /*background-color: #f5f5f5; /* Gri deschis pentru fundal */
  background-color: #f0f0f0;
  background-image: url('../assets/farm-background.png'); /* Fundal personalizat */
  background-size: cover; /* Acoperă întregul ecran */
  background-repeat: no-repeat; /* Nu repeta imaginea de fundal */
  background-attachment: fixed; /* Fixează fundalul */
  background-position: center; /* Poziționare centrală */
  margin: 0; /* Elimină marginile implicite ale body */
  color: #333; /* Culoare text implicită */
}

.MuiContainer-root {
  padding-top: 15px; /* Adaugă spațiu în partea de sus a containerului */
  background-color: rgba(255, 255, 255, 0.9); /* Fundal alb semi-transparent */
  border-radius: 10px; /* Colțuri rotunjite */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Umbră */
  padding: 20px; /* Spațiu interior */
}

.MuiCard-root {
  margin-bottom: 15px; /* Spațiu între card-uri */
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12); /* Umbră mai pronunțată */
  border-radius: 10px; /* Colțuri rotunjite */
}

.MuiCardContent-root {
  padding: 24px; /* Spațiu în interiorul card-urilor */
}

.MuiList-root {
  padding: 0; /* Elimină padding-ul implicit al listei */
}

.MuiListItem-root {
  padding: 10px 16px; /* Spațiu în interiorul elementelor de listă */
}

.MuiListItemIcon-root {
  min-width: 40px; /* Lățime minimă pentru iconițe */
}

.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
  font-weight: 500; /* Grosimea fontului pentru titluri */
}

.MuiButton-root {
  text-transform: none; /* Elimină transformarea textului în majuscule pentru butoane */
  background-color: #4CAF50; /* Green pentru butoane */
  color: white; /* Text alb */
  padding: 10px 20px; /* Spațiu interior */
  border-radius: 5px; /* Colțuri rotunjite */
  margin: 0 10px; /* Spațiu între butoane */
}

.MuiButton-root:hover {
  background-color: #45a049; /* Culoare de hover pentru butoane */
}

.circular-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Înălțime completă a ecranului */
}

.error-alert {
  margin-top: 15px;
}

.container {
  margin-top: 15px;
}

.user-info {
  margin-bottom: 15px;
}

#daily-claim-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px; /* Colțuri rotunjite */
}

#daily-claim-button:disabled { /* Stiluri pentru butonul dezactivat */
  background-color: #ccc;
  cursor: default;
}

.MuiCard-root {
  margin-bottom: 15px;
}

.MuiListItem-root {
  padding: 10px 16px;
}

.error-alert a { /* Sau ajustează selectorul în funcție de structura ta HTML */
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.settings-container {
  background-color: rgba(255, 255, 255, 0.9); /* Fundal alb semi-transparent */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}

.MuiFormControl-root {
  margin-top: 15px; /* Spațiu între select și titlu */
  width: 100%; /* Lățime completă */
}

.bottom-navigation {
  position: fixed;
  min-height: 25px;
  bottom: 0;
  width: 100%;
  left: 0; /* Asigură-te că meniul este centrat */
}
/* Add this to your CSS file */
.task-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-item {
  margin-bottom: 16px;
}

.task-item h3 {
  margin-bottom: 8px;
}

.task-item p {
  margin: 0;
  color: #555555;
}
/* Stiluri pentru link-ul din footer */

.footer-link {
  color: #1976d2; /* Exemplu de culoare secundară, ajustează conform temei tale */
  text-decoration: none; /* Elimină sublinierea */
  font-weight: bold; /* Face textul bold */
  padding: 5px 10px; /* Adaugă padding în jurul textului pentru un efect vizual mai plăcut */
  border: 1px solid #1976d2; /* Adaugă un chenar solid în jurul linkului */
  border-radius: 5px; /* Rotunjește colțurile chenarului */
  transition: all 0.3s ease; /* Efect de tranziție pentru hover */
}

.footer-link:hover {
  text-decoration: underline; /* Adaugă subliniere la hover */
  color: #6d1b7b; /* Exemplu de culoare primară la hover, ajustează conform temei tale */
  border-color: #6d1b7b; /* Schimbă culoarea chenarului la hover */
}

/* BoostFab.css */
@keyframes pulse {
  0% {
    box-shadow: 0 0 5px #ff0000;
  }
  50% {
    box-shadow: 0 0 20px #ff0000;
  }
  100% {
    box-shadow: 0 0 5px #ff0000;
  }
}

.boost-fab {
  animation: pulse 1.5s infinite;
}
